import React, {Component} from 'react';
import {withTranslate} from 'react-redux-multilingual'

// Custom Components

import FooterOne from "./common/footers/footer-one";

// ThemeSettings
import Header from "./Header";


class App extends Component {

    render() {

        return (
            <div>
                <Header/>
                <div>{/*style={{paddingTop: 60}}*/}
                    {this.props.children}
                </div>
                <FooterOne logoName={'logo.png'}/>
            </div>
        );
    }
}

export default withTranslate(App);