import React from 'react';

export const validPhone = str => {
    return /^\+?\d{10,15}$/.test(str);
};

export const validEmail = str => {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(str);
};

export function randomString(length) {
    return Math.round(
        Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
    )
        .toString(36)
        .slice(1);
}

export function nl2br(str, isXhtml) {
    // Some latest browsers when str is null return and unexpected null value
    if (typeof str === 'undefined' || str === null) {
        return '';
    }

    // Adjust comment to avoid issue on locutus.io display
    const breakTag =
        isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>';

    return (str + '').replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1');
}

export function nl2brReact(str) {
    const newlineRegex = /(\r\n|\r|\n)/g;

    if (typeof str === 'number') {
        return str;
    } else if (typeof str !== 'string') {
        return '';
    }

    return str.split(newlineRegex).map(function(line, index) {
        if (line.match(newlineRegex)) {
            return React.createElement('br', { key: index });
        }
        return line;
    });
}

export function createInitials(str) {
    const strArray = str.split(' ');
    let initials = '';

    if (strArray.length === 1) {
        initials = str.charAt(0);
    } else if (strArray.length === 2) {
        strArray.map(name => {
            initials += name.charAt(0);
            return name;
        });
    } else if (strArray.length > 2) {
        const nameCount = strArray.length;
        initials += strArray[0].charAt(0);
        initials += strArray[nameCount - 1].charAt(0);
    } else {
        initials = 'NA';
    }

    return initials;
}

export function number_format(number, decimals, decPoint, thousandsSep) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    const n = !isFinite(+number) ? 0 : +number;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = typeof thousandsSep === 'undefined' ? '.' : thousandsSep;
    const dec = typeof decPoint === 'undefined' ? ',' : decPoint;
    let s = '';

    const toFixedFix = function(n, prec) {
        const k = Math.pow(10, prec);
        return '' + (Math.round(n * k) / k).toFixed(prec);
    };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }

    return s.join(dec);
}
