import * as types from '../constants/ActionTypes'
import {empty} from "../helpers/generalHelper";

const authReducerDefaultState = {
    authenticated: !empty(localStorage.getItem('access_token'))
};

const authReducer = (state = authReducerDefaultState, action) => {
    switch (action.type) {

        case types.SIGN_IN:
            return {
                ...state,
                authenticated: true
            };

        case types.SIGN_OUT:
            return {
                ...state,
                authenticated: false
            };

        default:
            return state;
    }
}

export default authReducer;