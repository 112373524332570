import React, {Component} from 'react';
import {message, Modal, Spin} from "antd";
import {empty} from "../helpers/generalHelper";
import {apiCall} from "../helpers/networkHelper";
import {ACCOUNT_PROFILE, USER_NEWSLETTER_SUBSCRIBE, USER_NEWSLETTER_UNSUBSCRIBE} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import AccountMenu from "./atoms/AccountMenu";

class Account extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false,
            editProfileModalOpened: false,
            editProfileModalLoading: false,
            profile: null,
            newsletterSubscription: null,
            edit_name: '',
            edit_email: '',
            edit_gender: ''
        };
    }

    componentDidMount() {
        this.loadProfile();
    }

    loadProfile = () => {
        apiCall(
            ACCOUNT_PROFILE,
            'get',
            null,
            (res) => {
                const profile = res.data.profile;
                this.setState({
                    profile: profile,
                    edit_name: profile.name,
                    edit_email: profile.email,
                    edit_gender: profile.gender,
                    newsletterSubscription: res.data.newsletter_subscription
                });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    }

    onEditProfileModalOk = () => {
        const {
            edit_name,
            edit_gender,
            edit_email
        } = this.state;
        let validForm = true;

        if (empty(edit_name)) {
            message.error('Please enter your name');
            validForm = false;
        }

        if (empty(edit_gender)) {
            message.error('Please choose your gender');
            validForm = false;
        }

        if (empty(edit_email)) {
            message.error('Please enter your email address');
            validForm = false;
        }

        const formData = {
            name: edit_name,
            gender: edit_gender,
            email: edit_email
        };

        if (validForm) {
            this.setState({editProfileModalLoading: true});
            apiCall(
                ACCOUNT_PROFILE,
                'post',
                formData,
                (res) => {
                    message.success('Your profile have been successfully updated.');
                    this.setState({
                        editProfileModalOpened: false,
                        profile: null
                    }, () => {
                        this.loadProfile();
                    });
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                },
                res => {
                    this.setState({
                        editProfileModalLoading: false
                    });
                }
            );
        }
    };

    onEditProfileModalCancel = () => {
        this.setState({editProfileModalOpened: false});
    };

    onChangeName = e => {
        this.setState({edit_name: e.target.value});
    };

    onChangeGender = e => {
        this.setState({edit_gender: e.currentTarget.value});
    };

    onChangeEmail = e => {
        this.setState({edit_email: e.target.value});
    };

    subscribeNewsletter = () => {
        this.setState({pageLoading: true});
        apiCall(
            USER_NEWSLETTER_SUBSCRIBE,
            'post',
            null,
            (res) => {
                message.success(res.data.message);
                this.setState({
                    profile: null
                }, () => {
                    this.loadProfile();
                });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.setState({pageLoading: false});
            }
        );
    }

    unsubscribeNewsletter = () => {
        this.setState({pageLoading: true});
        apiCall(
            USER_NEWSLETTER_UNSUBSCRIBE,
            'post',
            null,
            (res) => {
                message.success(res.data.message);
                this.setState({
                    profile: null
                }, () => {
                    this.loadProfile();
                });
            },
            (err) => {
                ErrorHandler.handleGeneralError(err);
            },
            res => {
                this.setState({pageLoading: false});
            }
        );
    }

    render() {
        const {pageLoading, editProfileModalOpened, editProfileModalLoading, edit_name, edit_gender, edit_email, profile, newsletterSubscription} = this.state;

        return (
            <React.Fragment>
                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <AccountMenu activeMenu="profile" isMobile={true}/>

                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <AccountMenu activeMenu="profile"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        {/*<div className="page-title">
                                            <h2>My Dashboard</h2>
                                        </div>
                                        <div className="welcome-msg">
                                            <p>Hello, MARK JECNO !</p>
                                            <p>From your My Account Dashboard you have the ability to view a snapshot of
                                                your recent account activity and update your account information. Select
                                                a link below to view or edit information.</p>
                                        </div>*/}
                                        <div className="box-account box-info">
                                            <div className="box-head">
                                                <h2 style={{marginTop: 0}}>Account Information</h2>
                                            </div>
                                            <Spin spinning={profile === null || pageLoading} tip="Loading your profile..">

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="box">
                                                            <div className="box-title">
                                                                <h3>Personal Info</h3>
                                                                <a href="#" onClick={() => {
                                                                    this.setState({editProfileModalOpened: true})
                                                                }}>Edit</a>
                                                                <Modal
                                                                    title="Edit Profile"
                                                                    visible={editProfileModalOpened}
                                                                    onOk={this.onEditProfileModalOk}
                                                                    confirmLoading={editProfileModalLoading}
                                                                    onCancel={this.onEditProfileModalCancel}
                                                                >
                                                                    <form className="theme-form">
                                                                        <div className="form-row">
                                                                            <div className="col-md-12">
                                                                                <div style={{marginBottom: 20}}>
                                                                                    <label style={{fontWeight: 700}}
                                                                                           htmlFor="email">Name</label>
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           id="name"
                                                                                           placeholder="Enter your name"
                                                                                           value={edit_name}
                                                                                           onChange={this.onChangeName}
                                                                                           required/>
                                                                                </div>

                                                                                <div style={{marginBottom: 20}}>
                                                                                    <label style={{fontWeight: 700}}
                                                                                           htmlFor="gender">Gender</label>
                                                                                    <div style={{marginTop: 0}}>
                                                                                        <div
                                                                                            className="form-check form-check-inline">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                style={{marginBottom: 0}}
                                                                                                name="gender"
                                                                                                id="genderMALE"
                                                                                                value="MALE"
                                                                                                checked={edit_gender === 'MALE'}
                                                                                                onChange={this.onChangeGender}
                                                                                                required
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor="genderMALE">MALE</label>
                                                                                        </div>
                                                                                        <div
                                                                                            className="form-check form-check-inline">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                style={{marginBottom: 0}}
                                                                                                name="gender"
                                                                                                id="genderFEMALE"
                                                                                                value="FEMALE"
                                                                                                checked={edit_gender === 'FEMALE'}
                                                                                                onChange={this.onChangeGender}
                                                                                                required
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor="genderFEMALE">FEMALE</label>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div style={{marginBottom: 20}}>
                                                                                    <label style={{fontWeight: 700}}
                                                                                           htmlFor="email">Email</label>
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           id="email"
                                                                                           placeholder="Enter your email address"
                                                                                           value={edit_email}
                                                                                           onChange={this.onChangeEmail}
                                                                                           required=""/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </Modal>
                                                            </div>
                                                            <div className="box-content">
                                                                <table>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>Email Address</td>
                                                                        <td style={{
                                                                            width: 50,
                                                                            textAlign: 'center'
                                                                        }}>:
                                                                        </td>
                                                                        <td>{profile && profile.email}</td>
                                                                    </tr>
                                                                    {/*<tr>
                                                                        <td>Phone</td>
                                                                        <td style={{
                                                                            width: 50,
                                                                            textAlign: 'center'
                                                                        }}>:
                                                                        </td>
                                                                        <td>+62 812-8443-5553</td>
                                                                    </tr>*/}
                                                                    <tr>
                                                                        <td>Name</td>
                                                                        <td style={{
                                                                            width: 50,
                                                                            textAlign: 'center'
                                                                        }}>:
                                                                        </td>
                                                                        <td>{profile && profile.name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Gender</td>
                                                                        <td style={{
                                                                            width: 50,
                                                                            textAlign: 'center'
                                                                        }}>:
                                                                        </td>
                                                                        <td>{profile && profile.gender}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                                {/*<h6><a href="#">Change Password</a></h6>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="box">
                                                            <div className="box-title">
                                                                <h3>Newsletter Subscription</h3>
                                                                {/*<a href="#">Edit</a>*/}
                                                            </div>
                                                            <div className="box-content">
                                                                {!newsletterSubscription && (
                                                                    <p>
                                                                        You are currently not subscribed to our
                                                                        newsletter.<br/>
                                                                        <a href='javascript:;'
                                                                           onClick={this.subscribeNewsletter}>Subscribe
                                                                            Now</a>
                                                                    </p>
                                                                )}

                                                                {newsletterSubscription && (
                                                                    <p>
                                                                        You have subscribed to our newsletter.<br/>
                                                                        <a href='javascript:;'
                                                                           onClick={this.unsubscribeNewsletter}>Unsubscribe</a>
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Spin>
                                            {/*<div>
                                                <div className="box">
                                                    <div className="box-title">
                                                        <h3>Address Book</h3>
                                                        <a href="#">Manage Addresses</a>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <h6>Default Billing Address</h6>
                                                            <address>
                                                                You have not set a default billing address.<br/>
                                                                <a href="#">Edit Address</a>
                                                            </address>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h6>Default Shipping Address</h6>
                                                            <address>
                                                                You have not set a default shipping address.<br />
                                                                <a href="#">Edit Address</a>
                                                            </address>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        )
    }
}

export default Account