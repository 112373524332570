import axios from "axios";
import { API_BASE_URL } from "../utils/constants";

export const apiCall = (apiUrl, method = 'get', formData = {}, cbDone = false, cbFail = false, cbAlways = false) => {
    const url = API_BASE_URL + apiUrl;

    const errorObj = {
        status: 0,
        data: {
            error: {
                message: 'Connection failed, please try again'
            }
        }
    };

    switch (method.toLowerCase()){
        case 'get':
            axios.get(url)
                .then((response) => {
                    if(cbDone){ cbDone(response); }
                    if(cbAlways){ cbAlways(response); }
                })
                .catch((err) => {
                    if (err.response) {
                        if(cbFail){ cbFail(err.response); }
                        if(cbAlways){ cbAlways(err.response); }
                    } else {
                        if(cbFail){ cbFail(errorObj); }
                        if(cbAlways){ cbAlways(errorObj); }
                    }
                });
            break;

        case 'post': {
            axios.post(url, formData)
                .then((response) => {
                    if(cbDone){ cbDone(response); }
                    if(cbAlways){ cbAlways(response); }
                })
                .catch((err) => {
                    if (err.response) {
                        if(cbFail){ cbFail(err.response); }
                        if(cbAlways){ cbAlways(err.response); }
                    } else {
                        if(cbFail){ cbFail(errorObj); }
                        if(cbAlways){ cbAlways(errorObj); }
                    }
                });
            break;
        }

        case 'put': {
            axios.put(url, formData)
                .then((response) => {
                    if(cbDone){ cbDone(response); }
                    if(cbAlways){ cbAlways(response); }
                })
                .catch((err) => {
                    if (err.response) {
                        console.log(err.response);
                        if(cbFail){ cbFail(err.response); }
                        if(cbAlways){ cbAlways(err.response); }
                    } else {
                        if(cbFail){ cbFail(errorObj); }
                        if(cbAlways){ cbAlways(errorObj); }
                    }
                });
            break;
        }

        case 'delete': {
            axios.delete(url, {params: formData})
                .then((response) => {
                    if(cbDone){ cbDone(response); }
                    if(cbAlways){ cbAlways(response); }
                })
                .catch((err) => {
                    if (err.response) {
                        if(cbFail){ cbFail(err.response); }
                        if(cbAlways){ cbAlways(err.response); }
                    } else {
                        if(cbFail){ cbFail(errorObj); }
                        if(cbAlways){ cbAlways(errorObj); }
                    }
                });
            break;
        }

        default: {
            break;
        }
    }
};

export const promiseTimeout = function(ms, promise){
    let timeoutId;
    // Create a promise that rejects in <ms> milliseconds
    let timeout = new Promise((resolve, reject) => {
        timeoutId = setTimeout(() => {
            clearTimeout(timeoutId);
            reject('timeout');
        }, ms);
    });

    // Returns a race between our timeout and the passed in promise
    return Promise.race([
        promise,
        timeout
    ]).then((result) => {
        clearTimeout(timeoutId);
        return result;
    });
};