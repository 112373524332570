
import React, { Component } from 'react';
import Slider from 'react-slick';

class SmallImages extends Component {
    constructor (props) {
        super(props);
        this.state = {
            nav2: null
        }
    }
    componentDidMount() {
        this.setState({
            nav2: this.slider2
        });
    }

    render() {
        const { item, settings } = this.props;

        var productsnav = settings;

        return (
            <div className="row">
                <div className="col-12 p-0">
                    <div style={{padding: 20}}>
                    {item.pictures?
                        item.pictures.map((vari, index) =>
                            <div key={index} onClick={() => { this.props.onThumbnailClick(index); }} style={{width: 100, height: 100, overflow: 'hidden', display: 'inline-block', marginRight: 20, marginBottom: 20, cursor: 'pointer'}}>
                                <img style={{objectFit: 'cover'}} src={`${vari}`} key={index} alt=""  className="img-fluid" />
                            </div>
                        ):
                        item.pictures.map((vari, index) =>
                            <div key={index}>
                                <img src={`${vari}`} key={index} alt=""  className="img-fluid" />
                            </div>
                        )}
                    </div>
                    {/*<Slider {...productsnav} ref={slider => (this.slider2 = slider)} className="slider-nav">
                        {item.pictures?
                        item.pictures.map((vari, index) =>
                            <div key={index} onClick={() => { this.props.onThumbnailClick(index); }}>
                                <img style={{width: '100%', height: '0', paddingBottom: '100%'}} src={`${vari}`} key={index} alt=""  className="img-fluid" />
                            </div>
                        ):
                        item.pictures.map((vari, index) =>
                            <div key={index}>
                                <img src={`${vari}`} key={index} alt=""  className="img-fluid" />
                            </div>
                        )}
                    </Slider>*/}
                </div>
            </div>
        );
    }
}

export default SmallImages;