import {Component} from 'react';
import { empty } from '../helpers/generalHelper';
import { message } from 'antd';

export default class ErrorHandler extends Component{
    static handleGeneralError = errorResponse => {
        if(!empty(errorResponse)){
            if(errorResponse.status !== 400 && errorResponse.status !== 200){
                message.error(`Error ${errorResponse.status}: ${errorResponse.statusText}`);
            }

            if(errorResponse.status === 401){
                localStorage.clear();
                window.location.href = "/";
            }

            if(!empty(errorResponse.data)){
                if(!empty(errorResponse.data.error)){
                    if(!empty(errorResponse.data.error.message)){
                        message.error(errorResponse.data.error.message);
                    }
                }
            }
        }
    };
}