import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {nl2brReact, number_format} from "../../../../helpers/stringHelper";
import {empty} from "../../../../helpers/generalHelper";
import styles from "./DetailsPrice.module.scss";
import {Loader} from "react-overlay-loader";
import {apiCall} from "../../../../helpers/networkHelper";
import {ADD_TO_CART, CHECK_STOCK_LOCATIONS, WISHLIST} from "../../../../utils/endpoints";
import {LOCAL_DEVICE_ID} from "../../../../utils/constants";
import ErrorHandler from "../../../../classes/ErrorHandler";
import {message, Modal} from "antd";
import {closeCart, openCart, retrieveCart} from "../../../../actions";
import {connect} from "react-redux";
import {InlineShareButtons} from 'sharethis-reactjs';
import {SECONDARY} from "../../../../utils/colors";


class DetailsWithPrice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            quantity: 1,
            stock: 'InStock',
            nav3: null,
            size: '',
            pageLoading: false,
            loadingText: "",
            wishlisted: false,
            outOfStockClicked: false,
            stockLocationsLoading: false,
            stockLocations: null,
            stockRegions: []
        };
    }

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });

        if(this.props.authenticated){
            this.showLoader('Fetching wishlist status..');

            apiCall(
                WISHLIST + `?product_detail_id=${this.props.item.id}`,
                'get',
                null,
                (res) => {
                    this.setState({
                        wishlisted: !empty(res.data.user_wishlists)
                    });
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                },
                (res) => {
                    this.hideLoader();
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.doAddToCart !== this.props.doAddToCart && !empty(this.props.doAddToCart)){
            this.addToCart(this.props.item);
        }
    }

    showLoader = (loadingText = 'Loading..') => {
        this.setState({
            pageLoading: true,
            loadingText
        });
    };

    hideLoader = () => {
        this.setState({
            pageLoading: false,
            loadingText: ''
        });
    };

    addToCart = (item) => {
        let valid_form = true;

        if(empty(item)){
            valid_form = false;
            message.error('Item is not valid.');
        }else if(empty(item.id)){
            valid_form = false;
            message.error('Item is not valid.');
        }else if(empty(this.state.size)){
            valid_form = false;
            message.error('Please choose a size.');
        }else if(empty(this.state.quantity)){
            valid_form = false;
            message.error('Min quantity to add to cart is 1.');
        }

        if(valid_form){
            this.showLoader('Adding to cart..');
            const form_data = {
                local_device_id: LOCAL_DEVICE_ID(),
                product_detail_id: item.id,
                size: this.state.size,
                qty: this.state.quantity
            };

            apiCall(
                ADD_TO_CART,
                'post',
                form_data,
                (res) => {
                    message.success(res.data.message);
                    this.props.retrieveCart();
                    this.props.openCart();
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                },
                (res) => {
                    this.hideLoader();
                },
            );
        }
    };

    onOpenModal = () => {
        this.setState({open: true});
    };

    onCloseModal = () => {
        this.setState({open: false});
    };

    minusQty = () => {
        if (this.state.quantity > 1) {
            this.setState({
                stock: 'InStock',
                quantity: this.state.quantity - 1
            });
        }
    };

    plusQty = () => {
        this.setState({quantity: this.state.quantity + 1});
    };

    changeQty = (e) => {
        this.setState({quantity: parseInt(e.target.value)})
    };

    changeSize = size => {
        this.setState({
            size
        });
    };

    addToWishlist = (item) => {
        if(!this.props.authenticated){
            this.setState({
                redirect: '/sign-in'
            });
            message.error('Please sign in first to add wishlist');
        }else{
            this.showLoader('Adding to wishlist..');
            const form_data = {
                product_detail_id: item.id
            };

            apiCall(
                WISHLIST,
                'post',
                form_data,
                (res) => {
                    message.success(res.data.message);
                    this.setState({
                       wishlisted: true
                    });
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                },
                (res) => {
                    this.hideLoader();
                },
            );
        }
    };

    removeFromWishlist = (item) => {
        if(!this.props.authenticated){
            this.setState({
                redirect: '/sign-in'
            });
            message.error('Please sign in first to remove from wishlist');
        }else{
            this.showLoader('Removing from wishlist..');
            const form_data = {
                product_detail_id: item.id
            };

            apiCall(
                WISHLIST,
                'delete',
                form_data,
                (res) => {
                    message.success(res.data.message);
                    this.setState({
                        wishlisted: false
                    });
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                },
                (res) => {
                    this.hideLoader();
                },
            );
        }
    };

    showStockLocations = (item) => {
        let valid_form = true;

        if(empty(item)){
            valid_form = false;
            message.error('Item is not valid.');
        }else if(empty(item.id)){
            valid_form = false;
            message.error('Item is not valid.');
        }else if(empty(this.state.size)){
            valid_form = false;
            message.error('Please choose a size.');
        }else if(empty(this.state.quantity)){
            valid_form = false;
            message.error('Min quantity to check stock is 1.');
        }

        if(valid_form){
            this.showLoader('Checking..');
            const form_data = {
                product_detail_id: item.id,
                size: this.state.size,
                qty: this.state.quantity
            };

            apiCall(
                CHECK_STOCK_LOCATIONS,
                'post',
                form_data,
                (res) => {
                    // message.success(res.data.message);
                    this.setState({
                        stockLocations: res.data.showrooms,
                        stockRegions: res.data.showroom_regions
                    })
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                },
                (res) => {
                    this.hideLoader();
                },
            );
        }
    }

    closeStockLocationModal = () => {
        this.setState({
            stockLocations: null,
            stockRegions: []
        });
    }

    render() {
        const {symbol, item} = this.props;
        const {pageLoading, loadingText, stockLocations, stockRegions} = this.state;

        var colorsnav = {
            slidesToShow: 6,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };

        let disc = 0;
        if (item.grandPrice !== item.retailPrice) {
            disc = 100 - ((item.grandPrice / item.retailPrice) * 100).toFixed(0);
        }

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <div className="col-lg-6 rtl-text">
                <Loader fullPage loading={pageLoading} text={loadingText}/>

                <div className="product-right">
                    <h2 style={{
                        fontSize: 27,
                        fontFamily: "'DM Sans', sans-serif",
                        lineHeight: '1.3em',
                        textTransform: 'none',
                        marginTop: 20,
                        marginBottom: 0
                    }}> {item.name} </h2>
                    <p style={{marginBottom: 10}}>{item.product_code}</p>

                    {item.grandPrice !== item.retailPrice && (
                        <h4>
                            <del>{symbol} {number_format(item.retailPrice, 0)}</del>
                            {/*{!empty(item.directDiscPercent) && (*/}
                            <span>{disc}% off</span>
                            {/*)}*/}
                        </h4>
                    )}
                    <h3 style={{
                        fontSize: 20
                    }}>{symbol} {number_format(item.grandPrice, 0)}</h3>
                    {/*{item.variants?
                    <ul >
                        <Slider {...colorsnav} asNavFor={this.props.navOne} ref={slider => (this.slider1 = slider)} className="color-variant">
                            {item.variants.map((vari, i) => {
                                return <li className={vari.color} key={i} title={vari.color}></li>
                            })}
                        </Slider>
                    </ul>:''}*/}
                    <div className={`border-product ${styles.productDescription}`}>
                        <h6 className="product-title">product details</h6>
                        <p>{nl2brReact(item.description)}</p>
                    </div>
                    <div className="product-description border-product">
                        <h6 className="product-title">size</h6>
                        {item.size ?
                            <div>
                                {/*<h6 className="product-title size-text">select size
                                    <span><a href="#" data-toggle="modal"
                                             data-target="#sizemodal" onClick={this.onOpenModal} >size chart</a></span></h6>*/}
                                <div className="modal fade" id="sizemodal" tabIndex="-1"
                                     role="dialog" aria-labelledby="exampleModalLabel"
                                     aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered"
                                         role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"
                                                    id="exampleModalLabel">Sheer Straight
                                                    Kurta</h5>
                                                <button type="button" className="close"
                                                        data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`}
                                                     alt="" className="img-fluid"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="size-box">
                                    <ul>
                                        {item.sizes.map((size, i) => {
                                            let className = styles.size;
                                            if(this.state.size === size.size_code){
                                                className += ` ${styles.selectedSize}`;
                                            }

                                            if(size.is_available){
                                                return (
                                                    <li key={i} className={className}>
                                                        <a href="javascript:;" onClick={() => {
                                                            this.changeSize(size.size_code);
                                                            this.setState({outOfStockClicked: false})
                                                        }}>{size.size_name}</a>
                                                    </li>
                                                );
                                            }else{
                                                className += ` ${styles.disabledSize}`;
                                                return (
                                                    <li key={i} className={className}>
                                                        <a href="javascript:;" onClick={() => {
                                                            this.setState({outOfStockClicked: true})
                                                        }}>{size.size_name}</a>
                                                    </li>
                                                );
                                            }



                                        })}
                                    </ul>
                                </div>
                            </div> : ''}
                        {this.state.outOfStockClicked && (
                            <span style={{marginBottom: 10, display: 'block'}} className="instock-cls">This size is out of stock, but you can always add this item to wishlist</span>
                        )}

                        <h6 className="product-title">quantity</h6>
                        <div className="qty-box">
                            <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button type="button" className="btn quantity-left-minus" onClick={this.minusQty}
                                            data-type="minus" data-field="">
                                     <i className="fa fa-angle-left"/>
                                    </button>
                                  </span>
                                <input type="text" name="quantity" value={this.state.quantity} onChange={this.changeQty}
                                       className="form-control input-number"/>
                                <span className="input-group-prepend">
                                <button type="button" className="btn quantity-right-plus" onClick={this.plusQty}
                                        data-type="plus" data-field="">
                                <i className="fa fa-angle-right"/>
                                </button>
                               </span>
                            </div>
                        </div>
                    </div>

                    <div style={{marginBottom: 15}}>
                        <a style={{marginLeft: 0}} className="btn btn-solid" onClick={() => this.showStockLocations(item)}>
                            <i className="fa fa-search"/>&nbsp;&nbsp;&nbsp;Check Stock Locations
                        </a>
                    </div>

                    <div className={`product-buttons ${styles.productActionButtons}`}>
                        <a style={{marginLeft: 0}} className="btn btn-solid" onClick={() => this.addToCart(item)}>
                            <i
                                className="fa fa-shopping-cart"/>&nbsp;&nbsp;&nbsp;add to cart</a>
                        <span className={styles.separator}>&nbsp;&nbsp;</span>
                        {!this.state.wishlisted && (
                            <button className="btn btn-solid" onClick={() => {this.addToWishlist(item)}}>
                                <i className="fa fa-heart"/>&nbsp;&nbsp;&nbsp;
                                <span className="title-font">Add To WishList</span>
                            </button>
                        )}

                        {this.state.wishlisted && (
                            <button className="btn btn-link" onClick={() => {this.removeFromWishlist(item)}}>Remove From WishList</button>
                        )}



                        {/*<Link to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid"
                              onClick={() => BuynowClicked(item, this.state.quantity)}>buy now</Link>*/}
                    </div>

                    <div className={`border-product ${styles.productShare}`}>
                        <h6 style={{marginBottom: 10}} className={`product-title ${styles.sectionTitle}`}>share it</h6>

                        <InlineShareButtons
                            config={{
                                alignment: 'left',  // alignment of buttons (left, center, right)
                                color: 'social',      // set the color of buttons (social, white)
                                enabled: true,        // show/hide buttons (true, false)
                                font_size: 14,        // font size for the buttons
                                labels: 'null',        // button labels (cta, counts, null)
                                language: 'en',       // which language to use (see LANGUAGES)
                                networks: [           // which networks to include (see SHARING NETWORKS)
                                    'whatsapp',
                                    'messenger',
                                    'facebook',
                                    'twitter',
                                ],
                                padding: 12,          // padding within buttons (INTEGER)
                                radius: 4,            // the corner radius on each button (INTEGER)
                                show_total: false,
                                size: 40,             // the size of each button (INTEGER)

                                // OPTIONAL PARAMETERS
                                url: `https://coconutisland75.com/product/${item.id}`, // (defaults to current url)
                                image: item.coverImage,  // (defaults to og:image or twitter:image)
                                description: item.shortDetails,       // (defaults to og:description or twitter:description)
                                title: item.name,            // (defaults to og:title or twitter:title)
                                message: 'custom email text',     // (only for email sharing)
                                subject: 'custom email subject',  // (only for email sharing)
                                username: 'coconutisland75' // (only for twitter sharing)
                            }}
                        />

                        {/*<div className="product-icon">
                            <ul className="product-social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i className="fa fa-facebook"/></a>
                                </li>
                                <li><a href="https://plus.google.com/discover" target="_blank"><i
                                    className="fa fa-google-plus"/></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i className="fa fa-twitter"/></a>
                                </li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i
                                    className="fa fa-instagram"/></a></li>
                            </ul>
                            <button className="wishlist-btn" onClick={() => addToWishlistClicked(item)}><i
                                className="fa fa-heart"/><span
                                className="title-font">Add To WishList</span>
                            </button>
                        </div>*/}
                    </div>
                    {/*<div className="border-product">
                        <h6 className="product-title">Time Reminder</h6>
                        <div className="timer">
                            <p id="demo">
                                <span>25
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Days</span>
                                </span>
                                <span>22
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Hrs</span>
                                </span>
                                <span>13
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Min</span>
                                </span>
                                <span>57
                                    <span className="timer-cal">Sec</span>
                                </span>
                            </p>
                        </div>
                    </div>*/}
                </div>

                <Modal
                    title="Stock Locations"
                    visible={stockLocations !== null}
                    onOk={this.closeStockLocationModal}
                    onCancel={this.closeStockLocationModal}
                    cancelButtonProps={{style: {display: 'none'}}}
                    okButtonProps={{style: {backgroundColor: SECONDARY}}}
                >
                    {empty(stockRegions) && (
                        <div>No location available for this product..</div>
                    )}
                    {stockRegions.map(region => (
                        <div key={`Region${region.id}`} style={{marginBottom: 30}}>
                            <h3>{region.region_name}</h3>
                            {stockLocations
                                .filter(row => row.showroom_region_id === region.id)
                                .map(row => {
                                    return (
                                        <div key={`Location${row.id}`}>
                                            {row.showroom_name}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ))}
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated
});

export default connect(mapStateToProps,
    { retrieveCart, openCart, closeCart }
)(DetailsWithPrice)
// export default DetailsWithPrice;