import React, {Component} from 'react';
import FooterOne from "./common/footers/footer-one";
import Header from "./Header";

function TemplateSpecial(WrappedComponent, selectData) {
    return class extends Component {

        render() {

            return (
                <WrappedComponent>
                    <Header/>
                    <div style={{paddingTop: 60}}>
                        {this.props.children}
                    </div>
                    <FooterOne logoName={'logo.png'}/>
                </WrappedComponent>
            );
        }
    }
}

export default TemplateSpecial;