import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import '../../common/index.scss';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
// Import custom components
import TopCollection from './top-collection';
import LogoBlock from "../common/logo-block";
import {svgFreeShipping, svgoffer, svgservice} from "../../../services/script";

class Fashion extends Component {

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `#` );
    }

	render() {
		return (
			<div>
                <Helmet>
                    <title>Coconut Island | PT Warna Mardhika</title>
                    <meta name="description" content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses." />
                </Helmet>
                {/*Home Slider*/}
                <section className="p-0">
                    <Slider  className="slide-1 home-slider">
                        <div>
                            <div className="home home1 text-center"
                                 style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/wm/banner-hammer-parallax.jpg)`
                                }}
                            >
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                                {/*<div>
                                                    <h4>welcome to fashions</h4>
                                                    <h1>men fashion</h1>
                                                    <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} className="btn btn-solid">shop now</Link>
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="home home2 text-center"
                                 style={{
                                     backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/wm/banner-hammer-4.jpg)`
                                 }}
                            >
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                                {/*<div>
                                                    <h4>welcome to fashion</h4>
                                                    <h1>women fashion</h1>
                                                    <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} className="btn btn-solid">shop now</Link>
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </section>
                {/*Home Section End*/}

                {/*collection banner*/}
                {/*<section className="pb-0">
                    <div className="container">
                        <div className="row partition2">
                            <div className="col-md-6">
                                <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}>
                                    <div className="collection-banner p-right text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/banner-coconut.jpg`} className="img-fluid" alt=""/>
                                            <div className="contain-banner">
                                                <div>
                                                    <h4>save 30%</h4>
                                                    <h2>men</h2>
                                                </div>
                                            </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6">
                                <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}>
                                    <div className="collection-banner p-right text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/banner-nail.jpg`} className="img-fluid" alt=""/>
                                            <div className="contain-banner">
                                                <div>
                                                    <h4>save 60%</h4>
                                                    <h2>women</h2>
                                                </div>
                                            </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>*/}

                <section className="pb-0">
                    <div className="container">
                        <div className="row partition2">
                            <div className="col-md-6">
                                <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}>
                                    <div className="collection-banner p-right text-center" style={{position: 'relative'}}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/home-type1-1.jpg`} className="img-fluid" alt=""/>
                                        {/*<button className="home-type1-btn">SHOP NOW</button>*/}
                                        {/*<div className="contain-banner">
                                                <div>
                                                    <h4>save 30%</h4>
                                                    <h2>men</h2>
                                                </div>
                                            </div>*/}
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}>
                                            <div className="collection-banner p-right text-center" style={{position: 'relative'}}>
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/home-type1-2.jpg`} className="img-fluid" alt=""/>
                                                {/*<button className="home-type1-btn">SHOP NOW</button>*/}
                                                {/*<div className="contain-banner">
                                                <div>
                                                    <h4>save 60%</h4>
                                                    <h2>women</h2>
                                                </div>
                                            </div>*/}
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="col-md-12">
                                        <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}>
                                            <div className="collection-banner p-right text-center" style={{marginTop: 30, position: 'relative'}}>
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/wm/home-type1-3.jpg`} className="img-fluid" alt=""/>
                                                {/*<button className="home-type1-btn">SHOP NOW</button>*/}
                                                {/*<div className="contain-banner">
                                                <div>
                                                    <h4>save 60%</h4>
                                                    <h2>women</h2>
                                                </div>
                                            </div>*/}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*collection banner end*/}

                <TopCollection type={'women'} />

                <section className="pb-0">
                    <div className="container">
                        <div className="row partition2">
                            <div className="col-md-6">
                                <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}>
                                    <div className="collection-banner p-right text-center" style={{position: 'relative'}}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/home-type2-1.jpg`} className="img-fluid" alt=""/>
                                        {/*<div className="contain-banner">
                                                <div>
                                                    <h4>save 30%</h4>
                                                    <h2>men</h2>
                                                </div>
                                            </div>*/}
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6">
                                <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}>
                                    <div className="collection-banner p-right text-center" style={{position: 'relative'}}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/home-type2-2.jpg`} className="img-fluid" alt=""/>
                                        {/*<div className="contain-banner">
                                                <div>
                                                    <h4>save 60%</h4>
                                                    <h2>women</h2>
                                                </div>
                                            </div>*/}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>



                {/*Parallax banner*/}
                {/*<section className="p-0">
                    <div className="full-banner parallax-banner1 parallax text-center p-left"
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/wm/banner-hammer-parallax.jpg)`
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="banner-contain">
                                        <h2 style={{textAlign: 'left', marginBottom: 15}}>2020</h2>
                                        <h3 style={{textAlign: 'left', marginBottom: 10}}>fashion trends</h3>
                                        <h4 style={{textAlign: 'left'}}>special offer</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                {/*Parallax banner End*/}

                {/*<SpecialProducts />*/}

                {/*<div className="container" style={{
                    marginTop: 50,
                    marginBottom: 50
                }}>
                    <div className="row">
                        <div className="col-md-6">
                            <img style={{
                                maxWidth: '100%'
                            }}
                                 src={`${process.env.PUBLIC_URL}/assets/images/wm/hammer-half-banner-1.jpg`} />
                        </div>
                        <div className="col-md-6">
                            <img style={{
                                maxWidth: '100%'
                            }}
                                 src={`${process.env.PUBLIC_URL}/assets/images/wm/hammer-half-banner-2.jpg`} />
                        </div>
                    </div>
                </div>*/}

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/*<div>
                                <img style={{
                                    maxWidth: '100%'
                                }}
                                     src={`${process.env.PUBLIC_URL}/assets/images/wm/banner-promo-2.jpg`} />
                            </div>*/}

                            <div className="title1  section-t-space" style={{marginTop: 50}}>
                                <h4>Hammer</h4>
                                <h2 className="title-inner1" style={{marginBottom: 0}}>Shop By Category</h2>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexFlow: 'row wrap',
                                justifyContent: 'space-around',
                                margin: '70px 0'
                            }}>
                                {/*<img style={{
                                    width: '21%'
                                }}
                                     src={`${process.env.PUBLIC_URL}/assets/images/wm/category-1.jpg`} />
                                <img style={{
                                    width: '21%'
                                }}
                                     src={`${process.env.PUBLIC_URL}/assets/images/wm/category-2.jpg`} />*/}
                                <img style={{
                                    width: '48%'
                                }}
                                     src={`${process.env.PUBLIC_URL}/assets/images/wm/home-type3-1.jpg`} />
                                <img style={{
                                    width: '48%'
                                }}
                                     src={`${process.env.PUBLIC_URL}/assets/images/wm/home-type3-2.jpg`} />
                            </div>

                            {/*<div>
                                <img style={{
                                    maxWidth: '100%'
                                }}
                                     src={`${process.env.PUBLIC_URL}/assets/images/wm/banner-promo-1.jpg`} />
                            </div>*/}
                        </div>
                    </div>
                </div>

                {/*service layout*/}
                <div className="container">
                    <section className="service border-section small-section ">
                        <div className="row">
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                                    <div className="media-body">
                                        <h4>store pickup</h4>
                                        <p>choose our nearby outlets</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                                    <div className="media-body">
                                        <h4>fast process</h4>
                                        <p>within 1x24 hour guarantee</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                                    <div className="media-body">
                                        <h4>generous offers</h4>
                                        <p>enjoy our seasonal promo</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/*Blog Section end*/}
                {/*<div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="title1 section-t-space">
                                <h4>Recent Story</h4>
                                <h2 className="title-inner1">from the blog</h2>
                            </div>
                        </div>
                    </div>
                </div>*/}
                {/*<section className="blog p-t-0">
                    <BlogSection />
                </section>*/}
                {/*Blog Section End*/}

                {/*<Instagram />*/}

                {/*logo section*/}
                <LogoBlock />
                {/*logo section end*/}



			</div>
			)


	}
}

export default Fashion;